<template>
  <div class="Login-content">
    <div v-if="ShowCode">
      <!-- 登录 -->
      <div v-if="ShowLogin" class="hello">
        <Login @ClickLoginShow="ClickLoginShow" @ClickShowCode="ClickShowCode" />
      </div>
      <!-- 注册 -->
      <div v-else class="hello">
        <Sign @ClickLoginShow="ClickLoginShow" />
      </div>
    </div>
    <!-- 二维码 -->
    <div v-else class="hello">
      <Code @ClickShowCode="ClickShowCode" />
    </div>
    <router-view />
  </div>
</template>

<script>
import Login from "../../components/loginComponents/ChildLogin/Login";
import Code from "../../components/loginComponents/ChildLogin/Code";
import Sign from "../../components/loginComponents/ChildLogin/Sign";
import { mapMutations } from "vuex";
export default {
  name: "FatherLogin",
  data() {
    return {
      confirmDirty: false,
      ShowLogin: true,
      ShowCode: true
    };
  },
  components: {
    Login,
    Code,
    Sign
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  methods: {
    ...mapMutations(["changeLogin"]),
    // 登录注册切换
    ClickLoginShow(ShowLogin) {
      this.ShowLogin = ShowLogin;
    },
    // 二维码界面
    ClickShowCode(ShowCode) {
      this.ShowCode = ShowCode;
    },
  },
};
</script>

<style scoped>
.Login-content {
  background-image: url(../../assets/img/page/reg_bg_min.jpg);
  background-size: cover;
  /* width: 1900px; */
  min-height: 1920px;
  min-height: 99vh;
}
.hello {
  width: 600px;
  min-width: 800px;
  min-height: 99.9vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: red;
  border: none;
  color: rgba(0, 0, 0, 0.8);
}
</style>

